import type { FieldErrors } from 'react-hook-form';
import statusAlert from '~/assets/cds/icons/StatusAlert.svg';
import { Typography } from '~/cds/Typography';

interface ErrorSpanProps {
    message?: string;
    formError?: {
        name: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors: FieldErrors<any>;
        serverErrors?: Record<string, string>;
    };
}

export const ErrorSpan = ({ message, formError }: ErrorSpanProps) => {
    const errorMessage =
        message ||
        (formError &&
            ((formError.errors[formError.name]?.message as string) ||
                formError.serverErrors?.[formError.name]));

    if (errorMessage) {
        return (
            <Typography
                intent="body"
                size="xs"
                as="span"
                className="flex items-center gap-1 text-red-600"
            >
                <img src={statusAlert} className="h-3 w-3" alt="Alert" />
                {errorMessage}
            </Typography>
        );
    }

    return null;
};
